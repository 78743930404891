import { ADD_POST, ADD_PRODUCT, DELETE_POST, LOG_OUT, UPDATE_USER, ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART, UPDATE_QUANTITY } from "./types";


export const setUserData = (data) => ({
  type: UPDATE_USER,
  data
});

export const logout = () => ({
  type: LOG_OUT,
});


export const addPost = (post) => ({
  type: ADD_POST,
  payload: post,
});

export const deletePost = (postId) => ({
  type: DELETE_POST,
  payload: postId,
});

export const addProduct = (data) => ({
  type: ADD_PRODUCT,
  payload: data,
});


export const addToCart = (cartItem) => ({
  type: ADD_TO_CART,
  payload: cartItem,
});

export const removeFromCart = (productId) => ({
  type: REMOVE_FROM_CART,
  payload: productId,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const updateQuantity = (productId, quantity) => ({
  type: UPDATE_QUANTITY,
  payload: { productId, quantity },
});