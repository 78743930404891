import React, { useEffect } from "react";
import Navigation from "./Navigation";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function App() {
  const defaultTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#a3a3a3",
      },
      background: {
        default: "#171717",
        paper: "#121212",
      },
      text: {
        primary: "#a3a3a3",
        secondary: "#e4e4e4",
      },
    },
    typography: {
      h1: {
        color: "#fff",
      },
      h2: {
        color: "#fff",
      },
      h3: {
        color: "#fff",
      },
      h4: {
        color: "#fff",
      },
      h5: {
        color: "#fff",
      },
      h6: {
        color: "#fff",
      },
      button: {
        color: "#fff",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#171717",
            borderColor: "#4f4747",
            "&:hover": {
              backgroundColor: "#121212",
              color: "#fff",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#171717",
            borderColor: "#4f4747",
            "&:focus": {
              borderColor: "#fff",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#a3a3a3",
          },
        },
      },
    },
  });

  const { t } = useTranslation();
  console.log(t("logIn"));
  return (
    <ThemeProvider theme={defaultTheme}>
      <Navigation />
    </ThemeProvider>
  );
}
