import React from "react";
import { NavLink } from "react-router-dom";
import FormateNumber from "../../helpers/FormateNumber";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../redux/actions";
import ProductImage from "./ProductImage";

export default function Product({
  title,
  productThumb,
  price,
  id,
  description,
  photos,
  sku,
}) {
  const { cartItem } = useSelector((state) => state.cartItem);

  const dispatch = useDispatch();
  const hadledProduct = () => {
    dispatch(
      addProduct({ title, productThumb, price, id, description, photos, sku })
    );
  };
  return (
    <div className="cs_product_card cs_style_1">
      <NavLink onClick={hadledProduct} to={`/shop/${id}`}>
        <ProductImage
          src={productThumb}
          alt="Product"
          placeholder="/images/holde.png"
          products={true}
        />
      </NavLink>
      <div className="cs_product_info">
        <h2 className="cs_product_title">
          <NavLink onClick={hadledProduct} to={`/shop/${id}`}>
            {title}
          </NavLink>
        </h2>
        <p className="cs_product_price">
          Price: <FormateNumber number={price} />
        </p>
      </div>
    </div>
  );
}
