import { configureStore, applyMiddleware, combineReducers } from "@reduxjs/toolkit";
// import { postsReducer } from './postsReducer'
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from 'redux-thunk';
import { authReducer } from "./authReducer";
import { productReducer } from "./productReducer";

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { cartReducer } from "./cartReducer";




const rootReducer = combineReducers({
  // posts: postsReducer,
  auth: authReducer,
  product: productReducer,
  cartItem: cartReducer,
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk))
const store = configureStore({
  reducer: persistedReducer,
  composedEnhancer,
}
);
const persistor = persistStore(store)

export { store, persistor }