export const UPDATE_USER = 'UPDATE_USER';
export const LOG_OUT = 'LOG_OUT';

export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';

export const ADD_PRODUCT = 'ADD_PRODUCT'

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';

export const CALCULATE_TOTAL = 'CALCULATE_TOTAL';