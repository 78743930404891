import React, { useState } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import PostStyle4 from "../Post/PostStyle4";
import { Icon } from "@iconify/react";
import Sidebar from "../Sidebar";
import { pageTitle } from "../../helpers/PageTitle";
import { blogData } from "../../data/blog";

export default function BlogPage() {
  const [itemShow, setItemShow] = useState(4);
  pageTitle("Blog");
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      <h2 className="cs_section_title cs_fs_68 mb-0  text-center">Blog</h2>

      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="row cs_gap_y_60">
          <div className="col-lg-8">
            {blogData.slice(0, itemShow).map((item, index) => (
              <PostStyle4
                key={index}
                thumbnailSrc={item.thumbnailSrc}
                category={item.category}
                title={item.title}
                description={item.description}
                question={item.question}
                content={item.content}
                subTitle={item.subTitle}
                href={item.href}
                paragraph={item.paragraph}
                paragraph1={item.paragraph1}
                photos={item.photos}
                subTitle1={item.subTitle1}
                blockquote={item.blockquote}
                author={item.author}
                list={item.list}
                //   title,
                //   category,
                //   subTitle,
                //   paragraph,
                //   paragraph1,
                //   photos,
                //   subTitle1,
                //   lockquote,
                //   author,
                //   list,
              />
            ))}
            {blogData.length <= itemShow ? (
              ""
            ) : (
              <div className="text-center">
                <Spacing lg="95" md="50" />
                <span
                  className="cs_btn cs_style_1"
                  onClick={() => setItemShow(itemShow + 3)}>
                  Load More Blog
                  <span>
                    <i>
                      <Icon icon="fa6-solid:arrow-right" />
                    </i>
                    <i>
                      <Icon icon="fa6-solid:arrow-right" />
                    </i>
                  </span>
                </span>
              </div>
            )}
          </div>
          <div className="col-lg-3 offset-lg-1">
            <Sidebar />
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </div>
    </>
  );
}
