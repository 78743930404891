export const blogData = [
  {
    thumbnailSrc:
      "https://www.brsoftech.com/blog/wp-content/uploads/2020/10/React-native-UI-Framework.jpg",
    category: "React native",
    title: "best_ui_libraries_2024",
    description:
      "react_native_ui_intro",
    href: "/blog/blog-details",
    question: "why_use_ui_libraries",
    paragraph: [
      "ui_libraries_description",

      "benefits_of_using_ui_library",

      "saving_time_and_effort",

      "visual_consistency",

      "ease_of_use",

      "active_maintenance",

      "here_best_ui_libraries_2024",
    ],
    content: [
      {
        title: "React Native Paper",
        photo: "https://reactnativepaper.com/img/og-image.png",
        paragraph: [
          "react_native_paper_description",

          "react_native_paper_features",

          "<li> <a href='https://github.com/callstack/react-native-paper' target='_blank' rel='noopener noreferrer'>Github URL  </a> </li>",
          "<li> <a href='https://callstack.github.io/react-native-paper/' target='_blank' rel='noopener noreferrer'>Documentation </a> </li>",
        ],
      },
      {
        title: "Nativebase",
        photo:
          "https://s3-alpha.figma.com/hub/file/1805772087/6286980d-0778-4435-b9a5-e5a5b574a426-cover.png",
        paragraph: [
          "nativebase_description",

          "nativebase_key_features",

          "nativebase_cross_platform",

          "nativebase_customizable_themes",

          "nativebase_easy_to_use",

          "<li> <a href='https://github.com/GeekyAnts/nativebase' target='_blank' rel='noopener noreferrer'>Github URL  </a> </li>",
          "<li> <a href='https://nativebase.io/' target='_blank' rel='noopener noreferrer'>Documentation </a> </li>",
        ],
      },
      {
        title: "React Native Elements",
        photo:
          "https://user-images.githubusercontent.com/5962998/37248832-a7060286-24b1-11e8-94a8-847ab6ded4ec.png",
        paragraph: [
          "react_native_elements_description",

          "react_native_elements_key_features",

          "react_native_elements_cross_platform",
          "react_native_elements_easy_to_use",
          "react_native_elements_customizable",

          "<li> <a href='https://github.com/react-native-elements/react-native-elements' target='_blank' rel='noopener noreferrer'>Github URL  </a> </li>",
          "<li> <a href='https://reactnativeelements.com/' target='_blank' rel='noopener noreferrer'>Documentation </a> </li>",
        ],
      },

      {
        title: "React Native UI Kitten",
        photo:
          "https://user-images.githubusercontent.com/1452064/115417233-212a0100-a201-11eb-8bcf-0a60cca2e081.png",
        paragraph: [
          "react_native_ui_kitten_description",

          "react_native_ui_kitten_features",

          "<li> <a href='https://github.com/akveo/react-native-ui-kitten' target='_blank' rel='noopener noreferrer'>Github URL  </a> </li>",
          "<li> <a href='https://akveo.github.io/react-native-ui-kitten/' target='_blank' rel='noopener noreferrer'>Documentation </a> </li>",
        ],
      },

      {
        title: "Galio",
        photo:
          "https://camo.githubusercontent.com/16ff4fead7e6cc7f189b1b1c5f2ad4513f99d9f2699d427cd22495f3a76a782e/68747470733a2f2f692e696d6775722e636f6d2f4b4c4d315a62772e706e67",
        paragraph: [
          "galio_description",

          "galio_features",

          "<li> <a href='https://github.com/galio-org/galio' target='_blank' rel='noopener noreferrer'>Github URL  </a> </li>",
          "<li> <a href='https://galio.io/' target='_blank' rel='noopener noreferrer'>Documentation </a> </li>",
        ],
      },
    ],
  },
  {
    thumbnailSrc:
      "https://www.brsoftech.com/blog/wp-content/uploads/2020/10/React-native-UI-Framework.jpg",
    category: "React native",
    title:
      "expo_vs_react_native",
    description:
      "expo_vs_cli_intro",
    href: "/blog/blog-details",
    // question: "Expo",

    content: [
      {
        title: "Expo",
        // photo: "https://blixtdev.com/content/images/2022/10/exp.png",
        paragraph: [
          "expo_description",

          "expo_advantages",

          "expo_quick_start",

          "expo_ota_updates",

          "expo_pre_built_libraries",

          "expo_simplified_build_process",

          "expo_eas_services",

          "expo_testing_physical_devices",
        ],
      },
      {
        title: "expo_disadvantages",
        paragraph: [
          "expo_limited_native_modules",

          "expo_less_control_over_build",

          "expo_size_and_performance",
        ],
      },
      {
        title: "React Native CLI",
        paragraph: [
          "react_native_cli_description",

          "react_native_cli_advantages",

          "react_native_cli_full_native_module_support",

          "react_native_cli_customization_options",

          "react_native_cli_ecosystem_compatibility",
        ],
      },
      {
        title: "react_native_cli_disadvantages",
        paragraph: [
          "react_native_cli_learning_curve",

          "longer_development_setup_time",

          "react_native_cli_manual_build_process",

          "react_native_cli_native_physical_device",

          "react_native_ecosystem_compatibility",
        ],
      },
      {
        title: "choosing_between_expo_and_cli",
        paragraph: [],
        list: [
          {
            title: "choose_expo_if",
            data: [
              "choose_expo_if_beginner",

              "choose_expo_if_no_low_level_access",

              "choose_expo_if_value_simplicity",

              "expo_improvements_and_ejecting",
            ],
          },
          {
            title: "choose_react_native_cli_if",
            data: [
              "choose_react_native_cli_if_native_modules",

              "choose_react_native_cli_if_control_and_customization",

              "choose_react_native_cli_if_experience",
            ],
          },
        ],
      },
    ],
  },
];