import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { Icon } from "@iconify/react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ContactPage() {
  pageTitle("Contáctenos");
  const { t } = useTranslation();
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="contact_us_for_more_details_and_assistance"
        subTitle="contact"
        shape="shape_6"
      />

      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className=" cs_gray_bg_3 col-lg-12">
              <div className="cs_contact_form_wrap_act">
                <div className=" cs_contact_form_bg" />
                <form className="cs_contact_form">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("email_address")}
                    name="email"
                    autoComplete="email"
                    // autoFocus
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label={t("phoneNumber")}
                    name="phone"
                    autoComplete="phone"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />

                  <TextField
                    fullWidth
                    label={t("message")}
                    multiline
                    rows={4}
                  />

                  <div className="cs_height_38 cs_height_lg_25" />

                  {/* <div className="cs_height_38 cs_height_lg_25" /> */}
                  <button className="cs_btn cs_style_1">
                    {t("send")}
                    <span>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
