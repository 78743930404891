import React from "react";
import Hero from "../Hero";
import Spacing from "../Spacing";
import FunFact from "../FunFact";
import About from "../About";
import WhyChose from "../WhyChose";
import Service from "../Service";
import Portfolio from "../Portfolio";
import Button from "../Button";
import SectionHeading from "../SectionHeading";
import Award from "../Award";
import Accordion from "../Accordion";
import Cta from "../Cta";
import TestimonialSlider from "../Slider/TestimonialSlider";
import PostCarousel from "../Slider/PostCarousel";
import { pageTitle } from "../../helpers/PageTitle";
import HeroStyle5 from "../Hero/HeroStyle5";
import { useTranslation } from "react-i18next";

const whyChoseFeatureData = [
  {
    title: "whyChoseFeatureData-title-0",
    content: "whyChoseFeatureData-content-0",
  },
  {
    title: "whyChoseFeatureData-title-1",
    content: "whyChoseFeatureData-content-1",
  },
  {
    title: "whyChoseFeatureData-title-2",
    content: "whyChoseFeatureData-content-2",
  },
];
const serviceListData = [
  {
    title: "home_service_title_0",
    subtitle: "home_service_subtitle_0",
    imgUrl: "/images/act_dev_develomenpt.jpeg",
    href: "/service/service-details",
  },
  {
    title: "home_service_title_1",
    subtitle: "home_service_subtitle_1",
    imgUrl: "/images/act_services.jpeg",
    href: "/service/service-details",
  },
  {
    title: "home_service_title_2",
    subtitle: "home_service_subtitle_2",
    imgUrl: "/images/act_mobile_deveploment.jpeg",
    href: "/service/service-details",
  },
];
const portfolioData = [
  {
    href: "/portfolio/portfolio-details",
    imgUrl: "/images/creative-agency/portfolio_1.jpeg",
    title: "Awesome colorful artwork",
    btnText: "See Project",
  },
  {
    href: "/portfolio/portfolio-details",
    imgUrl: "/images/creative-agency/portfolio_2.jpeg",
    title: "Admin dashboard UI design",
    btnText: "See Project",
  },
  {
    href: "/portfolio/portfolio-details",
    imgUrl: "/images/creative-agency/portfolio_3.jpeg",
    title: "Product designing with brand",
    btnText: "See Project",
  },
  {
    href: "/portfolio/portfolio-details",
    imgUrl: "/images/creative-agency/portfolio_4.jpeg",
    title: "Kids education website design",
    btnText: "See Project",
  },
];

const faqData = [
  {
    title: "fab_title_0",
    content: "fab_content_0",
  },
  {
    title: "fab_title_1",
    content: "fab_content_1",
  },
  {
    title: "fab_title_2",
    content: "fab_content_2",
  },
  {
    title: "fab_title_3",
    content: "fab_content_3",
  },
  {
    title: "fab_title_4",
    content: "fab_content_4",
  },
  {
    title: "fab_title_5",
    content: "fab_content_5",
  },
  {
    title: "fab_title_6",
    content: "fab_content_6",
  },
  {
    title: "fab_title_7",
    content: "fab_content_7",
  },
  {
    title: "fab_title_8",
    content: "fab_content_8",
  },
  {
    title: "fab_title_9",
    content: "fab_content_9",
  },
  {
    title: "fab_title_10",
    content: "fab_content_10",
  },
  {
    title: "fab_title_11",
    content: "fab_content_11",
  },
];

export default function Home() {
  pageTitle("Zivan");
  const { t } = useTranslation();
  return (
    <>
      <HeroStyle5
        highlightTitle={t("home_highlightTitle")}
        title={t("home_title")}
        subTitle={t("home_subtile")}
        btnText={t("home_btnText")}
        btnUrl="/portfolio"
        videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
        videoBtnText="Reproducir video"
      />
      <Spacing lg="125" md="70" />
      {/* <div className="container">
        <FunFact data={funfactData} />
      </div> */}
      {/* <Spacing lg="125" md="70" /> */}
      <About
        thumbnail="/images/actdev.jpeg"
        uperTitle={t("home_about_uperTitle")}
        title={t("home_about_title")}
        subTitle={t("home_about_subtile")}
        featureList={[
          "home_about_list_0",
          "home_about_list_1",
          "home_about_list_2",
        ]}
        btnText={t("home_about_button")}
        btnUrl="/about"
      />
      <Spacing lg="185" md="75" />
      <WhyChose
        sectionTitle={t("home_why_sectionTitle")}
        sectionSubTitle={t("home_why_sectionSubTitle")}
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/creative-agency/why_choose_us_img_3.jpeg"
      />
      <Spacing lg="150" md="80" />
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title={t("home_header_services")}
            subTitle={t("home_header_services_subtile")}
            variantColor="cs_white_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title={t("home_hearder_portfolio")}
            subTitle={t("home_hearder_portfolio_subtitle")}
          />
          <Spacing lg="85" md="45" />
          <Portfolio data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center">
            <Button btnText={t("home_see_other")} btnUrl="/portfolio" />
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      {/* <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <SectionHeading
            title="Our prize achievement"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div>
        <Spacing lg="150" md="80" />
      </section> */}
      {/* <section className="cs_primary_bg">
        <TestimonialSlider
          layeredImages={[
            "/images/creative-agency/layer_img_1.jpeg",
            "/images/creative-agency/layer_img_2.jpeg",
            "/images/creative-agency/layer_img_3.jpeg",
            "/images/creative-agency/layer_img_4.jpeg",
            "/images/creative-agency/layer_img_5.jpeg",
          ]}
          data={testimonialData}
        />
      </section> */}

      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="" subTitle={t("home_frequency_question")} />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <Cta
            title={t("home_call_action")}
            btnText={t("home_contact")}
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
        <Spacing lg="120" md="50" />
      </section>
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section> */}
    </>
  );
}
