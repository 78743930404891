import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { addProduct } from "../../redux/actions";
import ProductImage from "../ShopComponents/ProductImage";
const API = "/data/AllProducts.json";
export default function RecentPost({ title }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(API)
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }, []);

  const hadledProduct = ({
    title,
    productThumb,
    price,
    id,
    description,
    photos,
    sku,
  }) => {
    dispatch(
      addProduct({
        title,
        productThumb,
        price,
        id,
        description,
        photos,
        sku,
      })
    );
  };
  return (
    <>
      <h4 className="cs-sidebar_widget_title">Templates</h4>
      <ul className="cs-recent_posts">
        {data?.map((item, index) => (
          <li key={index}>
            <div className="row">
              <Link to={`/shop/${item.id}`} onClick={() => hadledProduct(item)}>
                <ProductImage
                  src={item.productThumb}
                  alt="Product"
                  placeholder="/images/holde.png"
                  products={false}
                  className="cs_radius_15"
                />
              </Link>
              <div className="cs_height_10 cs_height_lg_5" />
              <div className="cs_height_10 cs_height_lg_5" />
              <div className="cs_product_info">
                <h5 className="cs_product_title">
                  <Link
                    to={`/shop/${item.id}`}
                    onClick={() => hadledProduct(item)}>
                    {item.title}
                  </Link>
                </h5>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
